@import '../../../css/global';

.wrap {
  padding: 0 0;
}

.brandWrap {
  // @include pxToClampMobile('margin-left', auto, 70px, auto);
}



  // Apply general styling to the form
  form {
    width: 75%; // Adjust the width as needed
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    font-weight: 300;

    div {
      width: 100%
    }

    p {
      font-size: 6px;
      color: red;
      margin-top: -10px;
      line-height: 1.39;
      letter-spacing: 1.02;
      text-transform: uppercase;
      position: absolute; 
      right: 2%;
      top: 30%;
      font-weight: 600;

    }

    span {
      line-height: 19.1px;
      font-size: 12px;
    }

    // Style labels
    label {
      color: white;
      margin-bottom: 3px;
      text-align: left;
      font-size: 9px;
      display: flex; // Enable flex layout for labels
      align-items: center; // Align items in a row
      line-height: 1.39;
      letter-spacing: 1.02;
      text-transform: uppercase;


      // Style checkbox
      input[type="checkbox"] {
        display: flex;
        justify-content: flex-end;
        width: 20px; // Adjust the width of the checkbox
        height: 20px;
        margin-left: 8px;
        margin-bottom: 5px;
      }

      
    }

    // Style text inputs and textarea
    input, textarea {
      width: 100%;
      padding: 4px;
      margin-bottom: 16px;
      color: black; // Text color for inputs
      background-color: white;
      box-sizing: border-box;
      font-size: 17px;
      line-height: 1.39;
      letter-spacing: 1.02;
    }

    textarea {
      height: 150px;
      font-size: 17px;
      line-height: 1.39;
      letter-spacing: 1.02;
    }
  }

.error {
  margin: auto 0;
  @include pxToClampMobile('font-size', auto, 10px, auto);
  color: red;
  padding-bottom: 20px;
  line-height: 1.39;
  letter-spacing: 1.02;
  text-transform: uppercase;

}

.brand {
  margin: 0 auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @include pxToClampMobile('width', auto, 50px * 0.9, auto);
  @include pxToClampMobile('height', auto, 40px * 0.9, auto);
  @include pxToClampMobile('margin-top', auto, 74px, auto);


  @include hidefont;
}

.prefix {
  margin: auto 0;
  @include pxToClampMobile('font-size', auto, 10px, auto);
  color: #fff;
  letter-spacing: 3%;;
  line-height: 1.4;

}


.intro {
  text-align: center;
  color: #fff;
  line-height: 1.39;
  @include pxToClampMobile('font-size', auto, 17px, auto);
  @include pxToClampMobile('padding-top', auto, 28px, auto);
  font-weight: 300;
  margin-top: 2px;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 28px;
} 

.disclaimer {
  text-align: center;
  width: 73%;
  margin: 0 auto;
  color: #fff;
  line-height: 1.4;
  @include pxToClampMobile('font-size', auto, 12px, auto);
  margin-top: 100px;
  padding-bottom: 40px;

}
.title {
  // margin: 20px auto 50px;
  margin: 0 auto;
  font-weight: 700;
  color: #fff;
  width: 80%;
  line-height: 0.95;
  text-align: center;

  @include pxToClampMobile('font-size', auto, 32px, auto);
  @include pxToClampMobile('padding-top', auto, 42px, auto);

}


[data-theme='ergo'] {
  .brand {
    @include pxToClampMobile('width', auto, 160px * 0.9, auto);
    @include pxToClampMobile('height', auto, 56px * 0.9, auto);
  }

  .title, .disclaimer, .prefix {
    font-weight: 300;
    font-family: "Encode Sans", sans-serif;
  }

  .error, label, input, p, span {
    font-family: "Encode Sans", sans-serif;
  }
}