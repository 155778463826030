@import '../../../css/global';

.wrap {
  // padding: 0 0 80px; 
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.brandWrap {
  height: 100%;
  // @include pxToClampMobile('margin-left', auto, 70px, auto);
}

.brand {
  margin: 0 auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @include pxToClampMobile('width', auto, 160px * 0.9, auto);
  @include pxToClampMobile('height', auto, 60px * 0.9, auto);

  @include hidefont;
}

.prefix {
  margin: 10px auto 0;
  font-weight: 300;
  @include pxToClampMobile('font-size', auto, 16px, auto);
  @include pxToClampMobile('padding-top', auto, 20px, auto);



  
  color: #fff;
  line-height: 1.3;
  max-width: 70%;
  
  p {
    @include pxToClampMobile('padding-bottom', auto, 20px, auto);
    margin: 0;

    &:last-child {
    @include pxToClampMobile('padding-bottom', auto, 0px, auto);

    }
  }
}

.contact {
  margin: 20px auto 0;

  color: #fff;
  font-weight: 300;
  line-height: 1.3;
  @include pxToClampMobile('font-size', auto, 16px, auto);
  @include pxToClampMobile('padding-top', auto, 10px, auto);
  width: 60%;
  h3 {
    color: #fff;
    margin-bottom: 5px;
  }

  p {
    margin: 0;
  }

  a {
    margin: 0;
  }
}

.title {
  margin: 20px auto 50px;

  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  color: #fff;
  width: 80%;

  @include pxToClampMobile('font-size', auto, 60px, auto);
}

[data-theme='ergo'] {
  .contact, .title, .prefix {
    font-family: "Encode Sans", sans-serif;
    max-width: 68%;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: 1.02;
  }
}