@import '../../../css/global';

.button {
  display: block;
  appearance: none;
  width: percentage(calc(433 / 1080));
  max-width: 433px;
  margin: 18px auto 0;
  background: #fff;
  // border: 1px solid #000;
  border-radius: 50px;

  line-height: 1;
  font-weight: bold;
  letter-spacing: 3%;
  font-weight: 600;
  // text-transform: uppercase;
  color: #000;

  @include pxToClampMobile('padding', auto, 10px, auto);
  @include pxToClampMobile('margin-top', auto, 60px, auto);
  @include pxToClampMobile('font-size', auto, 14px, auto);

  &.outline {
    background: none;
    color: #fff;
  }
}

[data-theme='ergo'] {
  .button {
    font-family: "Encode Sans", sans-serif;
    font-weight: 600;
  }
}