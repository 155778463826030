@import '../../../css/global';

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
  padding: 10px 16px;
  background: #fff;

  font-weight: 600;
  text-align: left;
  color: #000;

  @include pxToClampMobile('font-size', auto, 11px, auto);
  // @include pxToClampMobile('min-height', auto, 60px, auto);
}

.logo {
  flex: 0 0 auto;
  width: 50px;
  height: auto;
  padding-right: 20px;
}


.cross {
  margin: 0 auto;
  text-align: center;
  position: absolute;
  width: 20px;
  height: auto;
}

.chevron {
  flex: 0 0 auto;
  width: 30px;
  height: auto;
  padding-left: 20px;
}

.inner {
  flex: 1 1 auto;
  // max-width: 335px;
  margin: 0 auto;
  text-align: center;
  color: #fff;

  // @media (min-width: 768px) {
  //   max-width: none;
  // }
}

.text {
  // flex: 1 1 auto;
  // max-width: 335px;
  margin: 0 auto;
  text-align: center;
  position: absolute;

  p {
    font-size: 10px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 1.02;
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }

  h3 {
    margin: 0;
    padding: 0;
    font-size: 15px;
    font-weight: 600;
  }
  // @media (min-width: 768px) {
  //   max-width: none;
  // }
}

[data-theme='ergo'] {
  .text {
    font-family: "Encode Sans", sans-serif;
    p {
      font-weight: 400;
      line-height: 1.2;
    }
    h3 {
      font-weight: 600;
    }
  }
}